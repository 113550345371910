<template>
  <div>
    <div class="d-flex">
      <div class="mr-3">
        <a-input
          v-model="search"
          @keyup="searchStudent"
          size="large"
          placeholder="Search Student..."
        />
      </div>
      <div class="ml-auto">
        <a-button
          @click.prevent="addStudentSelected"
          :disabled="!selectedRowStudent.length"
          class="ml-3"
          type="primary"
          size="large"
          :icon="loadingAddStudent ? '' : ''"
          :loading="loadingAddStudent"
        >
          {{
            loadingAddStudent
              ? "Adding..."
              : `Add ${
                  selectedRowStudent.length ? selectedRowStudent.length : ""
                } Student(s) Selected`
          }}
        </a-button>
      </div>
    </div>
    <div class="mt-4">
      <a-table
        :columns="studentColumns"
        :data-source="studentDataTable"
        :pagination="paginationStudent"
        :loading="loadingTableStudent"
        :row-selection="rowSelection"
        @change="handleTableChange"
        bordered
      >
        <div slot="nis" slot-scope="value">
          <span v-if="value"> {{ value }}</span>
          <span v-else>-</span>
        </div>
        <div slot="nama" slot-scope="value">
          <span v-if="value"> {{ value }}</span>
          <span v-else>-</span>
        </div>
        <div slot="jenis_kelamin" slot-scope="value">
          <span v-if="value"> {{ value }}</span>
          <span v-else>-</span>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
const studentColumns = [
  {
    title: 'NIS',
    dataIndex: 'nis',
    key: 'nis',
    // width: 250,
    scopedSlots: { customRender: 'nis' },
  },
  {
    title: 'Name',
    dataIndex: 'nama',
    key: 'nama',
    scopedSlots: { customRender: 'nama' },
  },
  {
    title: 'Gender',
    dataIndex: 'jenis_kelamin',
    key: 'jenis_kelamin',
    // width: 200,
    sorter: true,
    scopedSlots: { customRender: 'jenis_kelamin' },
  },
]
export default {
  name: 'ManageStudent',
  props: {
    reportType: {
      type: String,
      required: true,
    },
    dataClass: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      studentColumns,
      studentDataTable: [],
      selectedRowStudent: [],
      paginationStudent: {},
      visibleModalAddStudent: false,
      loadingTableStudent: false,
      loadingAddStudent: false,
      search: '',
    }
  },
  methods: {
    addStudentSelected() {
      console.log(this.selectedRowStudent)
      this.$confirm({
        title: 'Warning',
        content: (
          <div class="d-flex flex-column"><p>Are you sure want to add {this.selectedRowStudent.length} student(s)?</p><p class="text-danger mt-4">*This change will be affected to system</p></div>
        ),
        onOk: () => {
          this.loadingAddStudent = true
          this.$store.dispatch('homeroom/ADD_STUDENT_CLASS_SELECTED', { idGuru: this.user.id, type: this.reportType, dataStudents: this.selectedRowStudent })
            .then(isSuccess => {
              this.loadingAddStudent = false
              this.fetchDataStudent()
              if (isSuccess) {
                this.$notification.success({
                  message: 'Success',
                  description: `${this.selectedRowStudent.length} student(s) has been added`,
                })
                this.selectedRowStudent = []
              } else {
                this.$notification.error({
                  message: 'Gagal',
                  description: `${this.selectedRowStudent.length} student(s) has not been added`,
                })
                this.selectedRowStudent = []
              }
            })
        },
        onCancel: () => {
        },
        centered: true,
        icon: 'warning',
        okType: 'primary',
        okText: 'Add Now',
        cancelText: 'Cancel',
      })
    },
    toggleModalAddStudent() {
      this.visibleModalAddStudent = !this.visibleModalAddStudent
      if (!this.visibleModalAddStudent) {
        this.fetchDataStudent()
      }
    },
    searchStudent() {
      this.fetchDataStudent({
        search: this.search,
      })
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.paginationStudent }
      let order = 'ASC'
      let sortBy = ''
      pager.current = pagination.current
      this.paginationStudent = pager
      if (sorter) {
        sortBy = sorter.field
        // console.log(sorter)
        if (sorter.order === 'ascend') {
          order = 'ASC'
        } else if (sorter.order === 'descend') {
          order = 'DESC'
        }
      }
      this.fetchDataStudent({
        page: pagination.current,
        search: this.search,
        order,
        sortBy,
      })
    },
    fetchDataStudent(params = { page: 1, order: 'ASC', search: '', type: '', sortBy: '' }) {
      console.log('fetch', this.dataClass)
      this.loadingTableStudent = true
      const type = this.reportType
      const idKelas = this.dataClass.idKelas
      this.$store.dispatch('homeroom/FETCH_LIST_STUDENT_OF_CLASS', { idKelas, type, page: params.page, order: params.order, search: params.search, sortBy: params.sortBy })
        .then(res => {
          this.loadingTableStudent = false
          const pagination = { ...this.paginationStudent }
          pagination.total = res.total
          this.paginationStudent = pagination
          this.studentDataTable = res.students.map(el => {
            return {
              ...el,
              key: el.id,
              nis: el.murid.nis,
              nama: el.murid.nama,
              jenis_kelamin: el.murid.jenis_kelamin,
            }
          })
        })
    },
  },
  mounted() {
    console.log('Mounted Add Student')
    this.fetchDataStudent()
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
          this.selectedRowStudent = selectedRows
        },
        getCheckboxProps: record => ({
          props: {
            disabled: Boolean(record.general_reports.length), // Column configuration not to be checked
          },
        }),
      }
    },
  },
}
</script>

<style>
</style>
